html,
body {
  background-color: #29364c;
  color: #deeaff;
  padding-top: 40px;
}
#root {
  height: 100vh;
}
.main {
  height: 100%;
}
.box-center {
  margin: 0 auto;
}
.text-center {
  text-align: center;
}
.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.containers {
  height: 83%;
}
.send-button {
  padding-right: 15%;
  padding-top: 5px;
  border: none;
  background-color: transparent;
}
.question-input {
  font-family: Roboto;
  font-size: 24px;
  line-height: 28px;
}
.question-input:focus {
  outline: none;
}
.copyright-wrapper {
  position: absolute;
  bottom: 0;
}
.button-as-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.transparent-links,
.transparent-links:active,
.transparent-links:focus,
.transparent-links:visited,
.transparent-links:hover {
  color: inherit;
  text-decoration: none; /* no underline */
}
/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {...}

/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {...}

/* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
//   .logo {
//     width: 390px;
//     height: 377px;
//   }
// }

/* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {
//   .logo {
//     width: unset;
//     height: unset;
//   }
// }

/* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {...}
