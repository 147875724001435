@media screen and (max-width: 450px) {
  .side-panel {
    display: none !important;
    text-align: center;
    * {
      font-weight: 200;
    }
    // color: white;
    // height: 100vh;
  }
  .enter-question-text {
    display: none;
  }
}
.side-panel {
  background: #29364c;
  width: 30%;
  * {
    font-weight: 200;
  }
  &-text {
    font-size: 24px;
    line-height: 29px;
  }
}

.enter-question-text {
  position: fixed;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 16px;
  line-height: 20px;
  bottom: 10%;
}
