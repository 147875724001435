.header {
  &-hamburger {
    position: absolute;
    top: 0;
    right: 30px;
    // margin-right: 10px;
  }
  &-logo {
    margin-top: 5px;
    margin-left: 14px;
  }
  &-username {
    margin: 15px 5px;
    margin-right: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
  }
  &-cols {
    padding-left: 0;
    padding-right: 0;
  }
}
