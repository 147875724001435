@media screen and (max-width: 450px) {
  .login-watson-container {
    margin-top: 30% !important;
    margin-bottom: 20% !important;
    // color: white;
    // height: 100vh;
  }
  .login-copyright-footer {
    position: relative !important;
  }
}

// @media screen and (max-width: 800px) {
//   .login-watson-container {
//     margin-top: 5% !important;
//     margin-bottom: 10% !important;
//     // color: white;
//     // height: 100vh;
//   }
// }

.login {
  // height: 70%;
  // &-with-watson {
  //   // margin: 38% 0;
  //   // margin: 25px;
  // }
  &-copyright-footer {
    // position: absolute;
    bottom: 0;
    padding-bottom: 0;
    width: 100%;
  }
  &-watson-container {
    margin-top: 25px;
    margin-bottom: 8%;
  }
  &-flex-container {
    // height: 100%;
  }
  &-welcome {
    // font-family: Filson Pro;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 25px;
  }
  &-inputs {
    text-align: center;
  }
  &-input,
  &-login-btn {
    background-color: #e5e5e5;
    border: none;
    margin: 7px auto;
    width: 100%;
  }
  &-login-btn {
    width: 115px;
    height: 38px;
    background: #00c0d4;
    color: #ffffff;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
  }

  &-trademark {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    // margin-bottom: 0;
  }
  &-forgot {
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    color: #deeaff;
  }
  &-error {
    font-size: small;
    color: red;
    text-align: center;
  }
}

.watson-logo-login {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
