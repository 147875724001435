.chat {
  // height: 83%;
  background-color: white;
  color: black;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 5%;
  top: 80px;
  // padding-top: 15px;
  // height: 93%;
  // padding-bottom: 4em;
  .arrow_box {
    position: relative;
    background: #fff;
    border: 4px solid #fff;
    width: 100%;
  }
  .arrow_box:after,
  .arrow_box:before {
    right: 100%;
    top: 40%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .arrow_box:after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: white;
    border-width: 30px;
    margin-top: -30px;
  }
  .arrow_box:before {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: white;
    border-width: 36px;
    margin-top: -36px;
  }

  &-element * {
    font-family: Roboto;
  }
  &-date {
    color: #c4c4c4;
    font-style: italic;
    font-weight: normal;
    margin-bottom: 0;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
  }
  &-right-bubble {
    min-width: 100%;
    min-height: 54px;
    border-right: #e4b278 10px solid;
    border-radius: 10px 0px 10px 10px;
    background-color: #f3f3f3;
    padding: 5px 10px;
  }

  &-left-bubble {
    width: 100%;
    min-height: 54px;
    background: #e4ecef;
    border-radius: 0px 10px 10px 10px;
    padding: 15px;
  }
  &-left-bubble-wrapper {
    padding-left: 0;
    img {
      width: 100%;
    }
  }
  &-element {
    margin-bottom: 20px;
  }
  &-question-wrapper {
    background-color: #e5e5e5;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 10%;
  }
  &-question-row {
    margin: 0;
    height: 100%;
  }
  &-question-input {
    // width: 100vw;
    height: 100%;
    margin: 0;
  }
  .three-dots {
    color: #29364c;
    font: 300 3em/10% Impact;
  }

  /* loading dots */

  .loading:after {
    content: " .";
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: #29364c;
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 #29364c, 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 #29364c, 0.5em 0 0 #29364c;
    }
  }
}

.panel-footer {
  // position: absol/ute;
  // width: 25.3%;
  padding: 5px 5px 5px 0;
  border: #29364c;
  // float: left;
  color: white;
  background-color: #29364c;
}

.footer-span {
  border: #29364c;
  // right: 0;
  // left: 0;
  margin-left: 5px;
}

.main-container {
  height: 90%;
}

.panel-footer {
  // position: absol/ute;
  // width: 25.3%;

  border: #29364c;
  // float: left;
  color: white;
  background-color: #29364c;
}

.footer-span {
  border: #29364c;
  // right: 0;
  // left: 0;
  margin-left: 5px;
}
