.menu {
  &-element {
    font-weight: 200;
    font-size: 36px;
    line-height: 232.2%;
    cursor: pointer;
    /* or 84px */

    text-align: center;
  }
  &-element-hover:hover {
    background-color: rgba(0, 0, 0, 0.205);
  }
}
.modal-content {
  background-color: rgba(0, 0, 0, 0.01) !important;
  border: none;
}
.modal-backdrop.show {
  opacity: 0.8;
}
